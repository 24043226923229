body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, #contact {
  font-family: 'Cabin', sans-serif;
  color: white;
}

h5 {
  color: white;
}

#more {
  background-color: rgba(5, 99, 181, 0.351);
  border-top: 1px solid rgba(5, 99, 181, 0.351);
}
